<template>
  <CBox>
    <CBox
      :d="['flex', 'flex']"
      gap="8px"
      align-items="center"
      p="16px"
      :opacity="disabled ? 0.5 : 1"
      justify-content="space-between"
    >
      <CBox
        d="flex"
        gap="8px"
        align-items="center"
        font-weight="500"
        font-size="18px"
        :flex="['unset','1']"
      >
        <CBox
          :class="{
            'radio': selectedItemId !== cart.id,
            'radio-active': selectedItemId === cart.id
          }"
        />
        <CImage
          :h="['60px', '102px']"
          :w="['60px', '80px']"
          object-fit="cover"
          :src="cart.productService.product.photoUrl"
          alt="Program Image"
          rounded="4px"
        />
        <CBox :d="['none', 'block']">
          <CText font-family="Roboto">
            {{ cart.productService?.product?.name }} {{ cart.productService.programsService }}
          </CText>
          <CText font-family="Roboto">
            {{ cart.productService.duration * cart.quantity }} Hari
          </CText>
          <router-link
            :to="getHandleMore"
          >
            <CText
              cursor="pointer"
              d="flex"
              gap="16px"
              align-items="center"
              font-size="16px"
              font-weight="400"
              color="#008C81"
              font-family="Roboto"
            >
              Selengkapnya
              <inline-svg
                :src="iconArrowRight"
                height="12"
                width="12"
                fill="#008C81"
              />
            </CText>
          </router-link>
        </CBox>
      </CBox>
      <CBox
        flex="1"
        :d="['none','flex']"
        justify-content="center"
      >
        <CText
          font-weight="500"
          font-size="18px"
          font-family="Roboto"
        >
          {{ formatCurrency(cart.productService ? (cart.productService.discountedPrice || cart.productService.price) * cart.quantity : 0) }}
        </CText>
      </CBox>
      <CBox
        font-weight="500"
        font-size="14px"
        flex="1"
        :d="['block', 'flex']"
        align-items="center"
      >
        <CBox :d="['block', 'none']">
          <CText font-family="Roboto">
            {{ cart.productService.programsService }}
          </CText>
          <CText font-family="Roboto">
            {{ cart.productService.duration * cart.quantity }} Hari
          </CText>
        </CBox>
        <CBox
          d="flex"
          w="100%"
          :justify-content="['space-between', 'center']"
          align-items="center"
        >
          <CText
            font-weight="700"
            font-size="18px"
            color="#008C81"
            font-family="Roboto"
            :d="['inline', 'none']"
          >
            {{ formatCurrency(cart.productService ? (cart.productService.discountedPrice || cart.productService.price) * cart.quantity : 0, 1) }}
          </CText>
          <CBox
            d="flex"
            :gap="['8px', '16px']"
            :flex-direction="['row', 'row-reverse']"
          >
            <button
              @click.stop="openModalConfirmDelete(cart)"
            >
              <inline-svg
                :src="iconTrash"
                height="18"
                width="18"
                fill="#D32737"
              />
            </button>
            <CBox class="control-quantity">
              <CBox
                as="button"
                pl="4px"
                py="8px"
                @click.stop="handleChangeQuantity('minus', cart)"
              >
                <inline-svg
                  :src="iconMinus"
                  height="13"
                  width="13"
                />
              </CBox>
              <CText
                font-family="Roboto"
                :font-size="['12px', '14px']"
                font-weight="400"
              >
                {{ cart.quantity }}
              </CText>
              <CBox
                as="button"
                pr="4px"
                py="8px"
                @click.stop="handleChangeQuantity('plus', cart)"
              >
                <inline-svg
                  :src="iconPlus"
                  height="13"
                  width="13"
                />
              </CBox>
            </CBox>
          </CBox>
        </CBox>
      </CBox>
      <ModalConfirmDelete
        :is-open="isOpenModalConfirmDelete"
        :is-deleting="isLoading"
        @handleDeleteItem="handleDeleteItem(cart)"
        @close="isOpenModalConfirmDelete = false"
      />
    </CBox>
    <CDivider
      :mx="['16px', '0px']"
      border-color="lightGray"
      m="0"
    />
  </CBox>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ModalConfirmDelete from '@/views/client/cart/_widgets/modal-confirm-delete.vue'
import { formatCurrency } from '@/utils/format-currency'
import iconTrash from '@/assets/icons/icon-trash.svg'
import iconMinus from '@/assets/icons/icon-minus.svg'
import iconPlus from '@/assets/icons/icon-plus.svg'
import iconArrowRight from '@/assets/icons/icon-arrow-right.svg'

export default {
  components: {
    ModalConfirmDelete,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    cart: {
      type: Object,
      default: () => ({}),
    },
    selectedItemId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      timeout: null,
      isOpenModalConfirmDelete: false,
      iconTrash,
      iconMinus,
      iconPlus,
      iconArrowRight,
    }
  },
  computed: {
    ...mapGetters({
      items: 'cart/items',
    }),
    getHandleMore() {
      if (this.cart?.productService?.isKonsultasi === 1) {
        return {
          name: 'client.program.consultation',
        }
      }
      if (this.cart?.productService?.isExtend === 1) {
        return {
          name: 'client.program.extend',
        }
      }
      return {
        name: 'client.program.detail',
        params: { id: this.cart?.productService?.product?.slug ? this.cart?.productService?.product?.slug : this.cart?.productId },
      }
    },
  },
  methods: {
    ...mapActions({
      onDeleteProduct: 'cart/onDeleteProduct',
      handleDeleteAll: 'cart/onDeleteAll',
      onUpdateCart: 'cart/onUpdateCart',
    }),
    formatCurrency,
    handleClickItem(cart) {
      this.$emit('clickMe', cart)
    },
    openModalConfirmDelete() {
      this.isOpenModalConfirmDelete = true
    },
    async handleDeleteItem(cart) {
      this.isLoading = true
      this.isOpenModalConfirmDelete = true
      await this.onDeleteProduct(cart.id)
      this.isOpenModalConfirmDelete = false
      this.isLoading = false
    },
    handleUpdateItem(cart) {
      this.$emit('handleUpdateItem', cart)
    },
    handleChangeQuantity(type, cart) {
      const findIndex = this.items.findIndex((item) => item.id === cart.id)
      const item = this.items[findIndex]
      if (type === 'minus') {
        if (item.quantity === 1) return
        item.quantity--
      } else if (type === 'plus') {
        item.quantity++
      }

      if (this.timeout) clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        this.onUpdateCart({
          productServiceId: item.productService.id,
          quantity: item.quantity,
        })
      }, 800) // delay

    },
  },
}
</script>

<style scoped>
.radio {
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  border: 2px solid #333333;
  border-radius: 100%;
}
.radio-active {
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  border: 2px solid #008C81;
  border-radius: 100%;
  position: relative;
}
.radio-active::after {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  background-color: #008C81;
}

.control-quantity {
  display: flex;
  gap: 8px;
  background-color: #C7F9E3;
  border: 1px solid #111111;
  border-radius: 8px;
  align-items: center;
}

</style>