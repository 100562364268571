<template>
  <CBox>
    <!-- Active -->
    <CBox
      v-if="filteredListCartsEnable.length >= 1"
      font-size="16px"
      font-weight="400"
      color="#008C81"
      bg="#C7F9E3"
      py="12px"
      :d="['none','flex']"
      mx="110px"
      rounded-top="12px"
    >
      <CText
        flex="1"
        text-align="center"
      >
        NAMA PROGRAM
      </CText>
      <CText
        flex="1"
        text-align="center"
      >
        HARGA
      </CText>
      <CText
        flex="1"
        text-align="center"
      >
        CHECKOUT
      </CText>
    </CBox>
    <CBox mb="8px">
      <CBox
        v-for="(item) in filteredListCartsEnable"
        :key="item.id"
        bg="white"
        :mx="['16px', '110px']"
        @click="handleClickItem(item)"
      >
        <ItemCart
          :cart="item"
          :selected-item-id="selectedCart.id"
        />
      </CBox>
    </CBox>

    <!-- Inactive -->
    <CBox v-if="filteredListCartsDisable.length >= 1">
      <CBox
        bg="white"
        rounded-top="8px"
        d="flex"
        justify-content="space-between"
        :mx="['16px', '110px']"
        align-items="center"
        py="16px"
        px="32px"
      >
        <CText
          :font-weight="['400', '700']"
          :font-size="['14px', '20px']"
          font-family="Roboto"
        >
          Belum bisa dibeli
        </CText>
        <CBox
          as="button"
          font-weight="400"
          font-size="12px"
          font-family="Roboto"
          color="#D32737"
          cursor="pointer"
          @click="openModalConfirmDelete"
        >
          Hapus semua
        </CBox>
      </CBox>
      <c-alert
        background-color="#FDEDCB"
        :mx="['16px', '110px']"
      >
        <inline-svg
          :src="iconCircleWarn"
          fill="#DA6D06"
          class="icon-alert"
        />
        <c-alert-description
          :font-size="['12px', '14px']"
          font-weight="400"
        >
          Program tidak dapat dipilih karena terdapat program lain yang sedang aktif
        </c-alert-description>
      </c-alert>
      <!--        @click="handleClickItem(item)"-->
      <CBox
        v-for="(item) in filteredListCartsDisable"
        :key="item.id"
        bg="white"
        :mx="['16px', '110px']"
      >
        <ItemCart
          :cart="item"
          :selected-item-id="selectedCart.id"
          :disabled="true"
        />
      </CBox>
    </CBox>
    <ModalConfirmDelete
      :is-open="isOpenModalConfirmDelete"
      :is-deleting="isLoading"
      @handleDeleteItem="handleDeleteAll"
      @close="isOpenModalConfirmDelete = false"
    />
  </CBox>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import iconCircleWarn from '@/assets/icons/icon-circle-warn.svg'
import ItemCart from '@/views/client/cart/_widgets/item-cart.vue'
import ModalConfirmDelete from '@/views/client/cart/_widgets/modal-confirm-delete.vue'

export default {
  components: { ModalConfirmDelete, ItemCart },
  data() {
    return {
      iconCircleWarn,
      isOpenModalConfirmDelete: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      items: 'cart/items',
      listCartsEnable: 'cart/listCartsEnable',
      listCartsDisable: 'cart/listCartsDisable',
      selectedCart: 'cart/selectedCart',
    }),
    filteredListCartsEnable() {
      return this.listCartsEnable.filter((item) => item.quantity >=1)
    },
    filteredListCartsDisable() {
      return this.listCartsDisable.filter((item) => item.quantity >=1)
    },
  },
  methods: {
    ...mapActions({
      fetchListCart: 'cart/list',
      onSubmitCheckout: 'cart/onSubmitCheckout',
      onDeleteProduct: 'cart/onDeleteProduct',
      detailProduct: 'clients/getProductById',
      onOpenModal: 'clients/onToggleModal',
      onDeleteAll: 'cart/onDeleteAll',
    }),
    ...mapMutations({
      setSelectedCart: 'cart/setSelectedCart',
    }),
    handleClickItem(cart) {
      this.setSelectedCart(cart.id)
    },
    openModalConfirmDelete() {
      this.isOpenModalConfirmDelete = true
    },
    async handleDeleteAll() {
      this.isLoading = true
      this.isOpenModalConfirmDelete = true
      await this.onDeleteAll()
      this.isOpenModalConfirmDelete = false
      this.isLoading = false
    },
  },
}
</script>

<style scoped>
.icon-alert {
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  margin-right: 16px;
}

@media(min-width: 767px) {
  .icon-alert{
    height: 25px;
    width: 25px;
    min-height: 25px;
    min-width: 25px;
  }
}
</style>
