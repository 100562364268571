var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CBox', {
    attrs: {
      "display": "flex",
      "height": "100%",
      "justify-content": "center",
      "font-family": "Roboto"
    }
  }, [_c('CBox', {
    attrs: {
      "display": "flex",
      "align-items": "center",
      "flex-direction": "column",
      "gap": "20px",
      "margin-top": "40px"
    }
  }, [_c('CText', {
    attrs: {
      "font-size": ['14px', '16px'],
      "font-weight": "400",
      "text-align": "center"
    }
  }, [_vm._v(" Anda belum memiliki program terpilih di keranjang ")]), _c('inline-svg', {
    attrs: {
      "src": _vm.imageEmptyCart,
      "height": "166",
      "width": "200"
    }
  }), _c('CButton', {
    attrs: {
      "variant-color": "primary",
      "border-radius": "1000px",
      "width": "269px",
      "height": "50px",
      "font-size": ['14px', '16px'],
      "font-weight": "500"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'client.select-program'
        });
      }
    }
  }, [_vm._v(" Pilih Program ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }