var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CBox', {
    attrs: {
      "bg": ['#F2F2F2', 'white'],
      "h": ['calc(100vh - 62px)', '100%'],
      "width": "100%",
      "pt": "16px",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['none', '16px']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: '/'
      }, {
        label: 'Keranjang',
        isCurrent: true
      }]
    }
  })], 1), _c('CText', {
    attrs: {
      "font-size": "20px",
      "font-weight": "700",
      "mb": "16px",
      "d": ['none', 'block'],
      "mx": "110px"
    }
  }, [_vm._v(" Keranjang ")]), _vm.hasCart ? _c('CBox', {
    attrs: {
      "w": "100%",
      "bg": ['#F2F2F2', 'white']
    }
  }, [_c('c-alert', {
    attrs: {
      "background-color": "#CDEDFC",
      "mx": ['16px', '110px'],
      "mb": "8px"
    }
  }, [_c('inline-svg', {
    staticClass: "icon-alert",
    attrs: {
      "src": _vm.iconCircleWarn,
      "fill": "#0C72E0"
    }
  }), _c('c-alert-description', {
    attrs: {
      "font-size": ['12px', '14px'],
      "font-weight": "400"
    }
  }, [_vm._v(" Melakukan pembelian dengan program yang sama akan mengakumulasikan durasi program tersebut ")])], 1), _c('ListCarts'), _c('CBox', {
    attrs: {
      "h": "149px"
    }
  }), true ? _c('CBox', {
    attrs: {
      "bg": "white",
      "position": ['fixed', 'sticky'],
      "bottom": "0",
      "left": "0",
      "d": ['flex', 'flex'],
      "w": "inherit",
      "flex-direction": ['column', 'row'],
      "align-items": ['none', 'center'],
      "justify-content": "space-between",
      "rounded-top": "12px",
      "p": "20px",
      "gap": "16px",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "mt": "16px"
    }
  }, [_c('CBox', {
    attrs: {
      "d": "flex",
      "justify-content": "space-between",
      "flex-direction": ['row', 'column']
    }
  }, [_c('CText', {
    attrs: {
      "color": "#888888",
      "font-size": ['14px', '16px'],
      "font-weight": "400"
    }
  }, [_vm._v(" Subtotal ")]), _c('CText', {
    attrs: {
      "color": "#008C81",
      "font-size": ['18px', '28px'],
      "font-weight": "700"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.selectedCart.productService ? _vm.price * _vm.selectedCart.quantity : 0, 1)) + " ")])], 1), _c('BaseButton', {
    attrs: {
      "screen": "desktop-only",
      "w": ['100%', '257px'],
      "font-size": ['14px', '18px'],
      "disabled": !_vm.selectedCart.id,
      "is-loading": _vm.isSubmittingCheckOrder
    },
    on: {
      "click": _vm.handleCheckOrder
    }
  }, [_vm._v(" Periksa Pesanan ")])], 1) : _vm._e()], 1) : _vm._e(), !_vm.hasCart ? _c('CBox', {
    attrs: {
      "mb": "16px"
    }
  }, [_c('EmptyCart')], 1) : _vm._e(), _c('ModalInfoDifferentProgram', {
    attrs: {
      "is-open": _vm.isOpenModalDifferentProgram
    },
    on: {
      "close": function close($event) {
        _vm.isOpenModalDifferentProgram = false;
      },
      "handle-confirm": _vm.handleContinuePurchase
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }