<template>
  <c-modal
    :is-open="isOpen"
    :on-close="handleClose"
    :close-on-overlay-click="isDeleting ? false : true"
    is-centered
    size="2xl"
  >
    <c-modal-content
      ref="content"
      class="modalss"
    >
      <c-modal-body>
        <CImage
          :src="imageIllustration"
          alt="success"
          pos="relative"
          z-index="1"
          mx="auto"
          mb="30px"
          :h="['200px', '300px']"
          :w="['200px', '300px']"
        />
        <CHeading
          as="h1"
          color="primary.400"
          font-size="28px"
          font-weight="600"
          margin-bottom="30px"
          font-family="Roboto"
        >
          Apa kamu yakin ingin menghapus program?
        </CHeading>
        <c-flex
          flex-direction="row"
          gap="20px"
          w="100%"
          mx="auto"
          align-items="center"
          justify-content="center"
          px="10px"
        >
          <c-button
            v-chakra="{
              ':hover': {
                bg: '#D32737'
              },
            }"
            w="100%"
            max-w="130px"
            h="34px"
            bg="#D32737"
            color="white"
            border-radius="16px"
            px="32px"
            py="7px"
            @click="handleClose"
          >
            <c-flex
              gap="12px"
              align-items="center"
              justify-content="center"
            >
              <c-text
                font-family="Roboto"
                :font-size="['14px']"
                :font-weight="['500']"
                align="center"
              >
                Batal
              </c-text>
              <c-image
                :src="require('@/assets/icons/icon-cancel.svg')"
                alt="toggle password visibility"
                :h="'24px'"
                :w="'24px'"
              />
            </c-flex>
          </c-button>
          <c-button
            :disabled="isDeleting"
            variant-color="primary"
            w="100%"
            max-w="130px"
            h="34px"
            color="white"
            border-radius="16px"
            px="14px"
            py="7px"
            font-family="Roboto"
            :font-size="['18px']"
            :font-weight="['500']"
            @click="handleConfirm"
          >
            <c-flex
              gap="12px"
              align-items="center"
              justify-content="center"
              flex-grow="1"
            >
              <c-text
                font-family="Roboto"
                :font-size="['14px']"
                :font-weight="['500']"
                align="center"
              >
                Konfirmasi
              </c-text>
              <c-image
                :src="require('@/assets/icons/icon-confirm.svg')"
                alt="toggle password visibility"
                :h="'24px'"
                :w="'24px'"
              />
            </c-flex>
          </c-button>
        </c-flex>
      </c-modal-body>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import iconHome from '@/assets/ic-home.svg'
import imageIllustration from '@/assets/images/illustration-7-raw.jpeg'

export default {
  props: {
    isOpen: {
      type: Boolean,
    },
    isDeleting: {
      type: Boolean,
    },
  },
  data() {
    return {
      iconHome,
      imageIllustration,
    }
  },
  methods: {
    handleConfirm() {
      this.$emit('handleDeleteItem')
    },
    handleClose() {
      if (this.isDeleting) return
      this.$emit('close')
    },
  },
}
</script>
<style scoped>
.modalss::v-deep > section {
  border-radius: 16px;
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
}
</style>