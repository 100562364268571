<template>
  <CBox
    :bg="['#F2F2F2', 'white']"
    :h="['calc(100vh - 62px)', '100%']"
    width="100%"
    pt="16px"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['none', '16px']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="[
          { label: 'Dashboard', href: '/' },
          { label: 'Keranjang', isCurrent: true },
        ]"
      />
    </Portal>
    <CText
      font-size="20px"
      font-weight="700"
      mb="16px"
      :d="['none', 'block']"
      mx="110px"
    >
      Keranjang
    </CText>
    <CBox
      v-if="hasCart"
      w="100%"
      :bg="['#F2F2F2', 'white']"
    >
      <c-alert
        background-color="#CDEDFC"
        :mx="['16px', '110px']"
        mb="8px"
      >
        <inline-svg
          :src="iconCircleWarn"
          fill="#0C72E0"
          class="icon-alert"
        />
        <c-alert-description
          :font-size="['12px', '14px']"
          font-weight="400"
        >
          Melakukan pembelian dengan program yang sama akan mengakumulasikan durasi program tersebut
        </c-alert-description>
      </c-alert>
      <ListCarts />

      <CBox h="149px" />

      <CBox
        v-if="true"
        bg="white"
        :position="['fixed', 'sticky']"
        bottom="0"
        left="0"
        :d="['flex', 'flex']"
        w="inherit"
        :flex-direction="['column', 'row']"
        :align-items="['none','center']"
        justify-content="space-between"
        rounded-top="12px"
        p="20px"
        gap="16px"
        :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
        mt="16px"
      >
        <CBox
          d="flex"
          justify-content="space-between"
          :flex-direction="['row', 'column']"
        >
          <CText
            color="#888888"
            :font-size="['14px', '16px']"
            font-weight="400"
          >
            Subtotal
          </CText>
          <CText
            color="#008C81"
            :font-size="['18px', '28px']"
            font-weight="700"
          >
            {{ formatCurrency(selectedCart.productService ? price * selectedCart.quantity : 0, 1) }}
          </CText>
        </CBox>
        <BaseButton
          screen="desktop-only"
          :w="['100%', '257px']"
          :font-size="['14px', '18px']"
          :disabled="!selectedCart.id "
          :is-loading="isSubmittingCheckOrder"
          @click="handleCheckOrder"
        >
          Periksa Pesanan
        </BaseButton>
      </CBox>
    </CBox>

    <CBox
      v-if="!hasCart"
      mb="16px"
    >
      <EmptyCart />
    </CBox>

    <!--  MODAL -->
    <ModalInfoDifferentProgram
      :is-open="isOpenModalDifferentProgram"
      @close="isOpenModalDifferentProgram = false"
      @handle-confirm="handleContinuePurchase"
    />
  </CBox>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import { Portal } from 'portal-vue'
import { CBox, CText } from '@chakra-ui/vue'
import EmptyCart from './_widgets/empty-cart.vue'
import ListCarts from './_widgets/list-carts.vue'
import iconCircleWarn from '@/assets/icons/icon-circle-warn.svg'
import { formatCurrency } from '@/utils/format-currency'
import { reqCompareProduct } from '@/requests/dietela-api/client/compare-product'
import ModalInfoDifferentProgram from '@/components/widgets/modal-info-different-program.vue'
import BaseButton from '@/components/elements/base-button.vue'

export default {
  name: 'CartPage',
  components: {
    BaseButton,
    ModalInfoDifferentProgram,
    BreadcrumbPath,
    Portal,
    CBox,
    EmptyCart,
    ListCarts,
    CText,
  },
  data() {
    return {
      iconCircleWarn,
      selectedItem: {},
      isOpenModalDifferentProgram: false,
      isSubmittingCheckOrder: false,
    }
  },
  computed: {
    ...mapGetters({
      hasCart: 'cart/hasCart',
      cartItems: 'cart/items',
      listCartsEnable: 'cart/listCartsEnable',
      listCartsDisable: 'cart/listCartsDisable',
      selectedCart: 'cart/selectedCart',
      userType: 'userType/userType',
    }),
    price() {
      return this.selectedCart.productService?.discountedPrice || this.selectedCart.productService.price
    },
  },
  mounted() {
    this.fetchListCart()
    this.triggerUpdateUserType()
  },
  methods: {
    ...mapActions({
      checkActiveProgram: 'clients/checkActiveProgram',
      fetchListCart: 'cart/list',
      detailProduct: 'clients/getProductById',
      onOpenModal: 'clients/onToggleModal',
      triggerUpdateUserType: 'userType/triggerUpdateUserType',
    }),
    onDetailProduct(item) {
      this.$router.push({ name: 'client.index', hash: '#program' })
      this.detailProduct(item.productId).then(() => this.onOpenModal(true))
    },
    formatCurrency,
    handleContinuePurchase() {
      this.isSubmittingCheckOrder = false
      // POSTHOG capture `plan purchased`
      this.$posthog.capture('plan purchased', {
        userType: this.userType ?? 'not-registered',
        program: this.selectedCart,
      })
      this.$router.push({ name: 'client.cart.check-order' })
    },
    async handleCheckOrder() {
      this.isSubmittingCheckOrder = true
      const showPopupInfo = await reqCompareProduct(
        this.$store.getters.axios,
        this.selectedCart?.productService?.id,
      ).then((res) => res.data?.data?.isPopUp).finally(() => {
        this.isSubmittingCheckOrder = false
      })
      if (showPopupInfo) {
        this.isOpenModalDifferentProgram = true
        return
      }
      this.handleContinuePurchase()
    },
  },
}
</script>

<style scoped>
.icon-alert {
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  margin-right: 16px;
}

@media(min-width: 767px) {
  .icon-alert{
    height: 25px;
    width: 25px;
    min-height: 25px;
    min-width: 25px;
  }
}
</style>
