<template>
  <CBox
    display="flex"
    height="100%"
    justify-content="center"
    font-family="Roboto"
  >
    <CBox
      display="flex"
      align-items="center"
      flex-direction="column"
      gap="20px"
      margin-top="40px"
    >
      <CText
        :font-size="['14px', '16px']"
        font-weight="400"
        text-align="center"
      >
        Anda belum memiliki program terpilih di keranjang
      </CText>
      <inline-svg
        :src="imageEmptyCart"
        height="166"
        width="200"
      />
      <CButton
        variant-color="primary"
        border-radius="1000px"
        width="269px"
        height="50px"
        :font-size="['14px', '16px']"
        font-weight="500"
        @click="$router.push({ name: 'client.select-program'})"
      >
        Pilih Program
      </CButton>
    </CBox>
  </CBox>
</template>

<script>
import { CBox, CButton, CText } from '@chakra-ui/vue'
import imageEmptyCart from '@/assets/icons/image-empty-cart.svg'

export default {
  components: {
    CBox,
    CButton,
    CText,
  },
  data() {
    return {
      imageEmptyCart,
    }
  },
}
</script>
