var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-modal', {
    attrs: {
      "is-open": _vm.isOpen,
      "on-close": _vm.handleClose,
      "close-on-overlay-click": _vm.isDeleting ? false : true,
      "is-centered": "",
      "size": "2xl"
    }
  }, [_c('c-modal-content', {
    ref: "content",
    staticClass: "modalss"
  }, [_c('c-modal-body', [_c('CImage', {
    attrs: {
      "src": _vm.imageIllustration,
      "alt": "success",
      "pos": "relative",
      "z-index": "1",
      "mx": "auto",
      "mb": "30px",
      "h": ['200px', '300px'],
      "w": ['200px', '300px']
    }
  }), _c('CHeading', {
    attrs: {
      "as": "h1",
      "color": "primary.400",
      "font-size": "28px",
      "font-weight": "600",
      "margin-bottom": "30px",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Apa kamu yakin ingin menghapus program? ")]), _c('c-flex', {
    attrs: {
      "flex-direction": "row",
      "gap": "20px",
      "w": "100%",
      "mx": "auto",
      "align-items": "center",
      "justify-content": "center",
      "px": "10px"
    }
  }, [_c('c-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          bg: '#D32737'
        }
      },
      expression: "{\n            ':hover': {\n              bg: '#D32737'\n            },\n          }"
    }],
    attrs: {
      "w": "100%",
      "max-w": "130px",
      "h": "34px",
      "bg": "#D32737",
      "color": "white",
      "border-radius": "16px",
      "px": "32px",
      "py": "7px"
    },
    on: {
      "click": _vm.handleClose
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": "12px",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px'],
      "font-weight": ['500'],
      "align": "center"
    }
  }, [_vm._v(" Batal ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/icons/icon-cancel.svg'),
      "alt": "toggle password visibility",
      "h": '24px',
      "w": '24px'
    }
  })], 1)], 1), _c('c-button', {
    attrs: {
      "disabled": _vm.isDeleting,
      "variant-color": "primary",
      "w": "100%",
      "max-w": "130px",
      "h": "34px",
      "color": "white",
      "border-radius": "16px",
      "px": "14px",
      "py": "7px",
      "font-family": "Roboto",
      "font-size": ['18px'],
      "font-weight": ['500']
    },
    on: {
      "click": _vm.handleConfirm
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": "12px",
      "align-items": "center",
      "justify-content": "center",
      "flex-grow": "1"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px'],
      "font-weight": ['500'],
      "align": "center"
    }
  }, [_vm._v(" Konfirmasi ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/icons/icon-confirm.svg'),
      "alt": "toggle password visibility",
      "h": '24px',
      "w": '24px'
    }
  })], 1)], 1)], 1)], 1)], 1), _c('c-modal-overlay')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }